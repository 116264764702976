import {
  FC,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  SyntheticEvent,
} from 'react'
import { useHits, useSearchBox, useConfigure } from 'react-instantsearch-hooks'
import {
  Button,
  Link,
  Media,
  ProductSearchResultPrice,
} from '@aether/components'
import {
  ProductHit,
  ShopifyImageMediaPayload,
  SiteConfiguration,
} from '@aether/models'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { ROUTE_BASE_PRODUCTS, ROUTE_BASE_SEARCH } from '@aether/configs'
import { CloseCircle, Search as SearchIcon } from '@aether/icons'
import debounce from 'lodash.debounce'

import {
  Index,
  Configure,
  useInstantSearch,
} from 'react-instantsearch-hooks-web'
import { SuggestionsHits } from './SuggestionsHits'
import { PagesHits } from './PagesHits'
import { getIndexName } from '@aether/search/utils-sanity-algolia-sync'
import useFilters from './useFilters'
import { trimProductNameGenderPrefix } from '@aether/utils'

import { PlainSearchParameters } from 'algoliasearch-helper'

const Wrap = styled('div', {
  padding: '$24 0',
  height: '100%',
  '@lg': {
    height: 'unset',
    padding: '$48 0',
  },
})
const StyledForm = styled('form', {
  width: '100%',
  height: '100%',
  position: 'relative',
  $containerSpace: 'small',
  display: 'grid',
  gridTemplateRows: 'min-content 1fr min-content',
  alignContent: 'stretch',
  '@lg': {
    $containerSpace: 'medium',
  },
})

const TopWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: '$16',
})

const SearchWrap = styled('div', {
  width: '100%',
  height: '$48',
  display: 'grid',
})
const StyledInput = styled('input', {
  background: 'none',
  width: '100%',
  display: 'block',
  gridColumn: 1,
  gridRow: 1,
  paddingLeft: '$48',
  paddingRight: '$64',
  $aetherFont: 'body01',
  $focusStyle: 'default',
  borderRadius: '$r1',
  border: '1px solid $black',
})

const CloseButton = styled(Button, {
  borderRadius: '$r2',
})

const IconWrap = styled('div', {
  width: '$48',
  height: '$48',
  gridColumn: 1,
  gridRow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const ClearButton = styled(Button, {
  gridColumn: 1,
  gridRow: 1,
  justifySelf: 'flex-end',
  $aetherFont: 'ui03',
  display: 'flex',
  alignSelf: 'center',
  marginRight: '$20',
})

// NOTE: We need to use ...-reverse direction to keep the correct focus ordering.
// First goes result cards, then suggestion links.
const SearchResults = styled('div', {
  display: 'flex',
  marginTop: '$24',
  flexDirection: 'column-reverse',
  '@lg': {
    flexDirection: 'row-reverse',
    marginTop: '$40',
  },
})

const TextResults = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '$8',
  gridColumn: 1,
  gridRow: 2,
  paddingBottom: '$8',
  '@lg': {
    display: 'block',
    marginRight: '$64',
    paddingBottom: '0',
  },
})

const ProductResults = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flexShrink: 0,
  '@lg': {
    width: '82%',
    marginLeft: 'auto',
  },
})

const ProductResultsWrap = styled('div', {
  alignSelf: 'stretch',
  // overflow: 'scroll',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridRowGap: '$20',
  gridColumn: 1,
  gridRow: 2,
  gridColumnGap: '$4',
  marginBottom: '$24',
  marginLeft: '-5px', // the negative margin is needed to offset the Result Margin
  '@lg': {
    marginLeft: 0,
    marginBottom: '$40',
    gridColumnGap: '$12',
    overflow: 'hidden',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
})
const Result = styled(Link, {
  display: 'grid',
  gridGap: '$12',
  margin: '5px  ',
  alignContent: 'start',
})
const Title = styled('p', {
  $aetherFont: 'heading06',
  textTransform: 'none',
})

const ShowResultsWrap = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gridColumn: 1,
  gridRow: 3,
  paddingBottom: '$24',

  '@lg': {
    paddingBottom: 0,
  },
})
const ShowResults = styled(Button, {
  width: '100%',
  '@lg': {
    width: 400,
  },
  variants: {
    showButton: {
      true: {
        display: 'grid',
      },
      false: {
        display: 'none',
      },
    },
  },
})
const ProductImageWrap = styled('div', {
  width: '100%',
  height: 0,
  paddingBottom: '130%',
  position: 'relative',
  display: 'block',
})
const MediaWrap = styled('div', {
  width: '100%',
  position: 'absolute',
  maxHeight: '100%',
  height: '100%',
  top: 0,
  bottom: 0,
})

const EmptyStateWrap = styled('div', {
  margin: '$40 0',
  gridColumn: 1,
  gridRow: 2,
  display: 'grid',
  alignContent: 'start',
  $aetherFont: 'ui03',
})

const SearchLinksTitle = styled('h2', {
  $aetherFont: 'heading07',
  marginBottom: '$28',
})
const StyledLink = styled(Link, {
  $aetherFont: 'ui03',
  padding: '$8 0',
  display: 'grid',
  alignSelf: 'start',
  justifySelf: 'start',
})

const NoResults = styled('div', {
  $aetherFont: 'ui01',
  textAlign: 'center',
  gridColumn: '1 / -1',
  gridRow: 1,
})

const PriceWrap = styled('div', {
  $aetherFont: 'ui06',
})

// NOTE: Screen readers ignore elements that are hidden using "display: none" or "visibility: hidden,"
// so we need to visually hide the aria-live element instead.
const ScreenReadersOnly = styled('div', {
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  border: '0',
})

type SearchDrawerProps = {
  handleClose: () => void
  searchConfig: SiteConfiguration['search']
}

interface SearchParametersWithFilters extends PlainSearchParameters {
  filters?: string
  hitsPerPage?: number
}

const createShopifyImagePayload: (src: string) => ShopifyImageMediaPayload = (
  src,
) =>
  ({
    type: 'shopify-image',
    image: {
      src,
      width: 461,
      height: 600,
    },
  } as ShopifyImageMediaPayload)

const Content: FC<SearchDrawerProps> = ({ handleClose, searchConfig }) => {
  const [searchValue, setSearchValue] = useState('')
  const [isCleared, setIsCleared] = useState(false)
  const { t } = useTranslation('search')
  const { query: queryFromAlgolia, clear, refine } = useSearchBox()
  const { status } = useInstantSearch()
  const { hits, results } = useHits<ProductHit>({}, {})
  const { pathname, query, push } = useRouter()
  const valueFromQuery = Array.isArray(query.v) ? query.v[0] : query.v
  const { scopedResults } = useInstantSearch()
  const { filters } = useFilters()

  useConfigure({ filters, hitsPerPage: 4 } as SearchParametersWithFilters)

  const debouncedRefine = useCallback(debounce(refine, 500), [])

  useEffect(() => {
    if (pathname === ROUTE_BASE_SEARCH && valueFromQuery) {
      setSearchValue(valueFromQuery)
    }
  }, [])

  useEffect(() => {
    if (searchValue) {
      debouncedRefine(searchValue)
    }
    return () => {
      debouncedRefine.cancel()
    }
  }, [searchValue])

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchValue && results && results?.nbHits > 0) {
      push(
        {
          pathname: ROUTE_BASE_SEARCH,
          query: { v: searchValue },
        },
        undefined,
        { shallow: pathname === ROUTE_BASE_SEARCH },
      )
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsCleared(false)
    const value = e.target.value
    setSearchValue(value)
  }

  const handleClear = () => {
    setIsCleared(true)
    clear()
    setSearchValue('')
  }

  const hasAnySearchResults = scopedResults.reduce(
    (accumulator, hitsPerIndex) => {
      if (accumulator) {
        return accumulator
      }

      return hitsPerIndex?.results?.nbHits > 0
    },
    false,
  )

  const shouldDisplaySearchResults =
    searchValue && searchValue === results?.query && queryFromAlgolia

  const [suggestionsAmount, setSuggestionsAmount] = useState(0)
  const [pagesAmount, setPagesAmount] = useState(0)

  const [searchResultsAriaLiveMessage, setSearchResultsAriaLiveMessage] =
    useState('')

  const changeSuggestionsAmount = (value: number) => setSuggestionsAmount(value)
  const changePagesAmount = (value: number) => setPagesAmount(value)

  useEffect(() => {
    if (status !== 'idle') return setSearchResultsAriaLiveMessage('')

    if (!pagesAmount && !suggestionsAmount && !results?.nbHits)
      return setSearchResultsAriaLiveMessage(t('noResultsAriaMessage') ?? '')

    if (isCleared && !results?.nbHits)
      return setSearchResultsAriaLiveMessage(
        t('searchSuggestionsCleared') ?? '',
      )

    if (!pagesAmount && !suggestionsAmount && results?.nbHits)
      return setSearchResultsAriaLiveMessage(
        t('resultsOnlyAriaMessage', { results: results?.nbHits }) ?? '',
      )

    return setSearchResultsAriaLiveMessage(
      t('fullSearchResultsAriaMessage', {
        suggestions: suggestionsAmount,
        pages: pagesAmount,
        results: results?.nbHits,
      }) ?? '',
    )
  }, [suggestionsAmount, pagesAmount, results?.nbHits, status, isCleared])

  return (
    <Wrap>
      <ScreenReadersOnly id="search-results-count" aria-live="polite">
        {searchResultsAriaLiveMessage}
      </ScreenReadersOnly>

      <StyledForm onSubmit={handleSubmit} autoComplete="off">
        <TopWrap>
          <SearchWrap>
            <IconWrap>
              <SearchIcon />
            </IconWrap>
            <StyledInput
              type="search"
              autoFocus
              enterKeyHint="search"
              value={searchValue}
              onChange={handleChange}
              autoComplete="off"
              aria-label="search"
              aria-expanded={shouldDisplaySearchResults ? true : false}
            />
            <ClearButton
              type="reset"
              onClick={handleClear}
              appearance="defaultLink"
            >
              {t('clear')}
            </ClearButton>
          </SearchWrap>
          <CloseButton
            type="button"
            appearance="block"
            onClick={handleClose}
            ariaLabel={'close search modal'}
          >
            <IconWrap>
              <CloseCircle />
            </IconWrap>
          </CloseButton>
        </TopWrap>

        {shouldDisplaySearchResults ? (
          <SearchResults>
            <ProductResults>
              <ProductResultsWrap>
                {hits.length > 0 &&
                  hits.slice(0, 4).map(
                    (hit) =>
                      hit.image && (
                        <Result
                          href={`${ROUTE_BASE_PRODUCTS}/${hit.handle}`}
                          key={hit.objectID}
                        >
                          <ProductImageWrap>
                            <MediaWrap>
                              <Media
                                // HACK: since algolia does not return the image properties we need to hardcode them here
                                mediaPayload={createShopifyImagePayload(
                                  hit.image,
                                )}
                                layout="fill"
                                hardcropRatio="portrait1"
                                sizes={{
                                  lg: '250px',
                                  xxl: '390px',
                                  default: '50vw',
                                }}
                              />
                            </MediaWrap>
                          </ProductImageWrap>
                          <Title>
                            {trimProductNameGenderPrefix(hit.title)}
                          </Title>
                          <PriceWrap>
                            <ProductSearchResultPrice hit={hit} />
                          </PriceWrap>
                        </Result>
                      ),
                  )}
              </ProductResultsWrap>
              <ShowResultsWrap>
                {results &&
                  results?.nbHits > 0 &&
                  searchValue &&
                  searchValue === results.query && (
                    <ShowResults type="submit" appearance="badgeBlack" size="M">
                      {t('showAll', { count: results?.nbHits })}
                    </ShowResults>
                  )}
              </ShowResultsWrap>
            </ProductResults>
            <TextResults>
              <Index indexName="primary_products_query_suggestions">
                <Configure
                  {...({
                    hitsPerPage: 3,
                    filters: '',
                  } as SearchParametersWithFilters)}
                />
                <SuggestionsHits
                  onHitClick={setSearchValue}
                  amountChangeHandler={changeSuggestionsAmount}
                />
              </Index>
              <Index indexName={getIndexName('pages')}>
                <Configure
                  {...({
                    hitsPerPage: 3,
                    filters: '',
                  } as SearchParametersWithFilters)}
                />
                <PagesHits amountChangeHandler={changePagesAmount} />
              </Index>
            </TextResults>
          </SearchResults>
        ) : (
          <EmptyStateWrap>
            <SearchLinksTitle>
              {searchConfig?.emptySearchLinksTitle}
            </SearchLinksTitle>
            <ul>
              {searchConfig?.emptySearchLinks.map((link, index) => (
                <li key={`${link.label}-${index}`}>
                  <StyledLink
                    {...link.link}
                    ariaLabel={
                      t('suggestedPageLabel', { value: link.label }) ?? ''
                    }
                  >
                    {link.label}
                  </StyledLink>
                </li>
              ))}
            </ul>
          </EmptyStateWrap>
        )}
      </StyledForm>
      {shouldDisplaySearchResults && !hasAnySearchResults && (
        <NoResults>{t('noResults')}</NoResults>
      )}
    </Wrap>
  )
}

export const SearchDrawer: FC<SearchDrawerProps> = (props) => {
  return <Content {...props} />
}
