import { FC } from 'react'
import { ShopifyProduct } from '@aether/models'
import { PriceRange } from '../components/PriceRange/PriceRange'
import { calculateCompareAtPriceRange } from './calculateCompareAtPriceRange'
import { Discount } from '@aether/discounts/utils-context'
import { calculatePriceRange } from './calculatePriceRange'
import { styled } from '@aether/styles'
import { useTranslation } from 'react-i18next'
import {
  PromoPriceWrapper,
  PromoPriceWrapperProps,
} from '../PromoPriceWrapper/PromoPriceWrapper'

const SRDescription = styled('span', {
  $visuallyHidden: true,
})

export const ProductPrice: FC<{
  product?: ShopifyProduct
  discount?: Discount
  promoPriceColor?: PromoPriceWrapperProps
}> = ({ product, discount, promoPriceColor = 'red' }) => {
  const { t } = useTranslation('product')

  if (!product || !product.priceRange) {
    return null
  }

  const compareAtPriceRange = calculateCompareAtPriceRange(product, discount)
  const priceRange = calculatePriceRange(product, discount)

  const isPriceRangeIdentical =
    priceRange.minVariantPrice.amount === priceRange.maxVariantPrice.amount
  const isCompareAtPriceRangeIdentical =
    compareAtPriceRange?.minVariantPrice.amount ===
    compareAtPriceRange?.maxVariantPrice.amount
  const showCompareAtPriceRange =
    compareAtPriceRange &&
    isPriceRangeIdentical &&
    isCompareAtPriceRangeIdentical &&
    parseFloat(compareAtPriceRange.minVariantPrice.amount) !== 0

  return showCompareAtPriceRange ? (
    <>
      <PromoPriceWrapper color={promoPriceColor}>
        <SRDescription>{t('onSaleAt')}</SRDescription>
        <PriceRange range={priceRange} />
      </PromoPriceWrapper>
      &nbsp;
      <del data-testid="compare-at-price">
        <SRDescription>{t('priceReducedFrom')}</SRDescription>
        <PriceRange range={compareAtPriceRange} />
      </del>
    </>
  ) : (
    <span>
      <SRDescription>{t('regularPrice')}</SRDescription>
      <PriceRange range={priceRange} />
    </span>
  )
}
